import request from '@/utils/request1';

// 原辅料使用记录查询
export function outboundList(data) {
    return request({ url: '/outbound/QOutboundApi', method: 'post', data })
}

export function saleExport(data) {
    return request({ url: `/download_word/DownloadWordApi`, method: 'post', data })
}

// 原辅料使用记录新增
export function outboundAdd(data) {
    return request({ url: '/outbound/AOutboundApi', method: 'post', data })
}

// 原辅料使用记录编辑
export function outboundEdit(data) {
    return request({ url: '/outbound/UOutboundApi', method: 'post', data })
}

// 原辅料使用记录删除
export function outboundDel(data) {
    return request({ url: '/outbound/DOutboundApi', method: 'post', data })
}

// 配方及投料记录查询
export function formulasList(data) {
    return request({ url: '/formulas/QFormulasApi', method: 'post', data })
}

// 配方及投料记录新增
export function formulasAdd(data) {
    return request({ url: '/formulas/AFormulasApi', method: 'post', data })
}

// 配方及投料记录编辑
export function formulasEdit(data) {
    return request({ url: '/formulas/UFormulasApi', method: 'post', data })
}

// 配方及投料记录删除
export function formulasDel(data) {
    return request({ url: '/formulas/DFormulasApi', method: 'post', data })
}

// 食品添加剂使用记录查询
export function additivesList(data) {
    return request({ url: '/food_additives/QFoodAdditivesApi', method: 'post', data })
}

// 食品添加剂使用记录新增
export function additivesAdd(data) {
    return request({ url: '/food_additives/AFoodAdditivesApi', method: 'post', data })
}

// 食品添加剂使用记录编辑
export function additivesEdit(data) {
    return request({ url: '/food_additives/UFoodAdditivesApi', method: 'post', data })
}

// 食品添加剂使用记录删除
export function additivesDel(data) {
    return request({ url: '/food_additives/DFoodAdditivesApi', method: 'post', data })
}

// 设备管理查询
export function deviceManageList(data) {
    return request({ url: '/device_manage/QDeviceManageApi', method: 'post', data })
}

// 设备管理新增
export function deviceManageAdd(data) {
    return request({ url: '/device_manage/ADeviceManageApi', method: 'post', data })
}

// 设备管理编辑
export function deviceManageEdit(data) {
    return request({ url: '/device_manage/UDeviceManageApi', method: 'post', data })
}

// 设备管理删除
export function deviceManageDel(data) {
    return request({ url: '/device_manage/DDeviceManageApi', method: 'post', data })
}

// 生产加工过程控制查询
export function pocessControlList(data) {
    return request({ url: '/process_control/QProcessControlApi', method: 'post', data })
}

// 生产加工过程控制新增
export function pocessControlAdd(data) {
    return request({ url: '/process_control/AProcessControlApi', method: 'post', data })
}

// 生产加工过程控制编辑
export function pocessControlEdit(data) {
    return request({ url: '/process_control/UProcessControlApi', method: 'post', data })
}

// 生产加工过程控制删除
export function pocessControlDel(data) {
    return request({ url: '/process_control/DProcessControlApi', method: 'post', data })
}

// 生产工艺参数查询
export function technologyList(data) {
    return request({ url: '/technology/QTechnologyApi', method: 'post', data })
}

// 生产工艺参数新增
export function technologyAdd(data) {
    return request({ url: '/technology/ATechnologyApi', method: 'post', data })
}

// 生产工艺参数编辑
export function technologyEdit(data) {
    return request({ url: '/technology/UTechnologyApi', method: 'post', data })
}

// 生产工艺参数删除
export function technologyDel(data) {
    return request({ url: '/technology/DTechnologyApi', method: 'post', data })
}

// 留存样品留样记录查询
export function inspectList(data) {
    return request({ url: '/inspects/QInspectsApi', method: 'post', data })
}

// 留存样品留样记录新增
export function inspectAdd(data) {
    return request({ url: '/inspects/AInspectsApi', method: 'post', data })
}

// 留存样品留样记录编辑
export function inspectEdit(data) {
    return request({ url: '/inspects/UInspectsApi', method: 'post', data })
}

// 留存样品留样记录删除
export function inspectDel(data) {
    return request({ url: '/inspects/DInspectsApi', method: 'post', data })
}

// 检验报告查询
export function reportList(data) {
    return request({ url: '/report/QReportApi', method: 'post', data })
}

// 检验报告新增
export function reportAdd(data) {
    return request({ url: '/report/AReportApi', method: 'post', data })
}

// 检验报告编辑
export function reportEdit(data) {
    return request({ url: '/report/UReportApi', method: 'post', data })
}

// 检验报告删除
export function reportDel(data) {
    return request({ url: '/report/DReportApi', method: 'post', data })
}
